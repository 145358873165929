<template>
  <nav class="navbar navbar-expand-md navbar-dark nav-bar-gradient-bg ">
    <a href="#" class="navbar-brand ps-5 pe-4"
    ><img src="@/assets/images/logo_Ymanci.svg" class="logo-ymanci-header"
    /></a>
    <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#toggleMobileMenu"
        aria-controls="toggleMobileMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- vue desktop uniquement -->
    <div class="collapse navbar-collapse ps-1 ms-5 d-none d-md-block" id="toggleMobileMenu">
      <ul class="navbar-nav mr-auto text-center">
        <li><b class="text-white espace_perso">Mon espace personnel</b></li>
      </ul>
    </div>
  <!-- -- -->
    <div class="collapse navbar-collapse" id="toggleMobileMenu">
      <ul class="navbar-nav ms-auto text-center">
        <li class="px-4 d-block d-md-none">
          <router-link class="nav-link text-white" :to="MY_PROFILE_PATH">
            <img src="@/assets/images/profil.svg" class="px-1 ico-pourcent"/> Mon profil
          </router-link>
        </li>
        <!-- Vue mobile uniquement -->
        <li class="px-4 d-block d-md-none">
          <a @click="confirmLogOut" class="nav-link text-white" href="#">
            <img src="@/assets/images/remove-file.svg" class="px-1 ico-pourcent"/> Déconnexion</a>
        </li>
        <li class="px-4">
          <a class="nav-link text-white" target="_blank" href="https://ymanci.fr/regroupement-credits/outils/simulation-allegement-mensualite"
          ><img src="@/assets/images/simulation.svg" class="px-1 ico-pourcent"/>
            <b> Faire une simulation</b></a
          >
        </li>
        <!-- -- -->
        <!-- Dropdown (vue desktop) -->
        <li class="dropdown nav-bar-arrow pointer d-none d-md-block" @click="triggerDropDownMenu()">
          <a
              href="#"
              class="nav-link dropdown-toggle text-white"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          ><img src="@/assets/images/profil.svg" class="px-3"/><b>{{ fullName }}</b></a>
          <ul
              class="dropdown-menu dropdown-menu-position" v-bind:class="{ show : isActive }"
              aria-labelledby="dropdown"
          >
            <li>
              <router-link class="dropdown-item sidebar-text" :to="MY_PROFILE_PATH">Mon profil</router-link>
            </li>
            <div class="dropdown-divider sidebar-text"></div>
            <li>
              <a @click="confirmLogOut" class="dropdown-item sidebar-text" href="#">Déconnexion</a>
            </li>
          </ul>
        </li>
        <!-- -- -->
      </ul>
    </div>

    <ConfirmLogoutAccountPopup />
  </nav>
</template>
<script>
import {computed} from "vue";
import {mapActions, useStore} from "vuex";
import store from "@/store";
import emitter from '../events/emitter.js';
import Client from '../models/client';
import { MY_PROFILE_PATH } from '../router/pathes';
import ConfirmLogoutAccountPopup from "./Popups/ConfirmLogoutAccountPopup.vue";

export default {
  name: 'NavBar',
  props: {

  },
  components: {
    ConfirmLogoutAccountPopup
  },
  created() {
    this.MY_PROFILE_PATH = MY_PROFILE_PATH;
  },
  setup() {
    const store = useStore();

    const fullName = computed(() => store.getters[`getUserFullName`]);
    const mainClient = computed(() => store.getters[`client/getMainClient`]);

    return {
      fullName,
      mainClient
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    ...mapActions('client', ['getMainClient']),
    getData() {
      // get client data
      if (!this.mainClient) {
        this.getMainClient().then((client) => {
          store.commit("setUserFullname", (new Client(client)).getFullname());
        });
      }
    },
    confirmLogOut() {
        emitter.emit('show-confirm-logout');
      },
    triggerDropDownMenu(){
        this.isActive = !this.isActive;
      }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>
.nav-bar-gradient-bg {
  background: rgb(25, 23, 81);
  background: linear-gradient(
      180deg,
      rgba(25, 23, 81, 1) 0%,
      rgba(39, 62, 155, 1) 100%
  );
}

.ico-pourcent {
  height: 13px;
}

@media (min-width: 1912px) {
  .navbar-brand {
    margin-left: 2%;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    align-items: flex-start;
    margin-left: 3% !important;
  }

  .navbar-toggler {
    margin-right: 8%;
    margin-top: 2%;
  }
}

.dropdown-menu-position {
  background: rgb(39, 56, 146);
  background: linear-gradient(180deg, rgba(39, 56, 146, 1) 0%, rgba(57, 111, 251, 1) 100%);
  border: 0;
  top: 51px !important;
  z-index: 1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  position: absolute;
  border-radius: 20px;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.377);
}

@media (max-width: 991px) {
  .dropdown-menu-position {
    left: -105px;
  }
}

.dropdown-divider {
  background: white;
  margin-left: 15px;
  width: 81%
}

.dropdown .dropdown-item:hover { background-color: transparent; font-weight: bold; }

.sidebar-text {
  color: white;
}

.nav-bar-arrow {
  margin-right: 50px
}

.espace_perso { font-size: 12px; }

</style>
