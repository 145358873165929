<template>
  <footer class="col-12 col-xl-10 offset-xl-1 pt-4 pb-1 text-start">
    <div class="row">
      <div class="col">
        <img src="@/assets/images/Ymanci_Logo_v2.svg" class="px-2 px-lg-0 footer-img" />
      </div>
    </div>

    <div class="row">
      <div class="col footer-content pb-0">
        <p class="px-2 px-lg-0 footer-span-beg">
          {{Footer_span_Beg}}
        </p>
        <p class="px-2 px-lg-0 footer-size">
          {{Footer_Span}}
        </p>
        <p class="px-2 px-lg-0 footer-size">
          {{Footer_Span_Following}}
        </p>
        <p class="px-2 px-lg-0 cgu-link footer-size">
          <a :disabled="isCguLinkDisabled" @click="downloadCgu" class="cgu-link">{{Footer_CGU}}</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from "vuex";
import { base64Download } from "../utils/file.js";

export default {
    data() {
        return {
            Footer_span_Beg : "Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.",
            Footer_Span : "Offre soumise à conditions sous réserve d’acceptation par l’un de nos partenaires bancaires, prêteurs. Aucun versement de quelque nature que ce soit ne peut être exigé d’un particulier avant l’obtention d’un ou plusieurs prêts d’argent." +
                " La diminution du montant des mensualités peut entraîner l’allongement de la durée du remboursement, majorer le coût total du crédit et augmenter l’endettement total. " +
                "Pour une opération de regroupement de crédits soumise au régime du crédit immobilier, l'emprunteur dispose d'un délai de réflexion de dix jours à la réception de l’offre de prêt. " +
                "Si la vente est subordonnée à l'obtention du prêt et que celui-ci n'est pas obtenu, le vendeur doit rembourser à l’emprunteur les sommes versées. Pour une opération de regroupement de crédits soumise au régime du crédit à la consommation, " +
                "l’emprunteur peut se rétracter sans motifs dans un délai de quatorze jours calendaires révolus à compter du jour de l’acceptation de l’offre de contrat de crédit." +
                "Ymanci Regroupement de crédits, marque commerciale de PREMIDIRECT, SAS au capital de 234 000 € – Siège social : Espace P - 3 Route de Nancy 54840 Gondreville – " +
                "RCS Nancy n° 421 422 734 – ORIAS : 07002351 – Courtier et mandataire non exclusif en opérations de banque – Courtier et mandataire d’intermédiaire d’assurance (informations disponibles sur www.orias.fr) – " +
                "Soumis au contrôle de l’Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest CS 92459 75436 PARIS Cedex 9, www.acpr.banque-france.fr – " +
                "Service Réclamations : Ymanci Regroupement de crédits, Espace P - 3 Route de Nancy 54840 Gondreville ou reclamations@ymanci.fr - " +
                "PREMIDIRECT est mandataire non exclusif en opérations de banque de : CA CONSUMER FINANCE, 1 rue Victor Basch 91068 MASSY / BNP PARIBAS PERSONAL FINANCE, 1 boulevard Haussmann 75 318 PARIS Cedex 09 / CFCAL, " +
                "1 rue du Dôme – BP 102 – 67 003 STRASBOURG Cedex / CGL, 69 avenue de Flandres 59 708 MARCQ-EN-BAROEUL Cedex / CREATIS, 61 avenue Halley – Parc de la Haute Borne – 59 650 VILLENEUVE D’ASCQ / MY MONEY BANK, " +
                "Tour Europlaza 92 063 PARIS LA DEFENSE Cedex / LA BANQUE POSTALE CONSUMER FINANCE, 1-3 avenue François Mitterrand 93 212 LA PLAINE ST DENIS / YOUNITED, 21 rue de Châteaudun 75 009 PARIS.",
            Footer_Span_Following : "",
            Footer_CGU : "CGU Espace Client - Charte Vie Privée",
            isCguLinkDisabled: false
        };
    },
    methods: {
        ...mapActions('cgu', ['getCurrentDocument']),
        downloadCgu() {
          this.isCguLinkDisabled = true;
          this.getCurrentDocument().then((data) => {
            if (data && data.fileContent) {
                base64Download(data.fileContent, 'cgu.pdf');
            }
          }).finally(() => {
            this.isCguLinkDisabled = false;
          });
        }
    }
}
</script>

<style scoped lang="scss">
.cgu-link {
  text-decoration: none;

  &:hover {
    color: #00a8ff;
    cursor: pointer;
  }
}

.footer-img {
  max-width: 150px;
  margin-left: -17px;
}

.footer-content {
  font-size: 10px;
  color: #4c4c9a;
}

.footer-span-beg {
  font-weight: bold;
  font-size: 10px;
}

.footer-size {
  font-size: 8px;
  text-align: justify;
}
</style>
