<template>
    <div class="row bg-family px-2 contentheader">
        <div class="col-12 col-xl-10 offset-xl-1 main-content-header">
            <h3 class="pt-5 text-start"><strong>Bonjour {{fullName}}</strong></h3>
            <h3 class="py-1 text-start"><strong>Bienvenue dans votre espace client Ymanci.</strong></h3>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const fullName = computed(() => store.getters[`getUserFullName`]);

    return {
      fullName
    }
  },
}
</script>

<style>
  .contentheader { height: 136px; }
  .contentheader h3 { font-size: 20px; letter-spacing: 0; }

</style>
